<template>
    <div>
        <div class="images">
            <ul>
                <viewer :images="imgUrlList"  @inited="inited"  ref="viewer">
                    <li v-for="(value, index) in imgUrlList" :key="index">
                        <div class="image">
                            <img :src="value.path" alt="图片">
                        </div>
                        <span v-cloak>{{ value.title }}</span>
                    </li>
                </viewer>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgUrlList: [
                { path: require('../assets/home/device.png') },
                { path: require('../assets/home/smart.png') },
                { path: require('../assets/home/alarm.png') },
                { path: require('../assets/home/system.png') },
                { path: require('../assets/home/device.png') },
                { path: require('../assets/home/smart.png') },
                { path: require('../assets/home/alarm.png') },
                { path: require('../assets/home/system.png') },
                { path: require('../assets/home/device.png') },
                { path: require('../assets/home/smart.png') },
                { path: require('../assets/home/alarm.png') },
                { path: require('../assets/home/system.png') },
                
            ]
        }
    },
   
    methods: {
        inited(viewer) {
            // @inited="inited"
            this.$viewer = viewer
            console.log(this.$viewer.options)
            this.$viewer.index = this.activeIndex
            // 不要他的按钮
            this.$viewer.options.button = true;
            // 不要他的底部缩略图
            this.$viewer.options.navbar = true;
            // 不要他的底部标题
            this.$viewer.options.title = false;
            // 不要他的底部工具栏
            this.$viewer.options.toolbar = true;
            // this.show()
        },
        // 调用显示
        show() {
            this.$viewer.show()
        },
    }
}
</script>
<style>
.viewer-canvas{
    background: #000000;
}
</style>
<style scoped>
.images {
    background: #000000;
    width: 100vw;
}

ul div {
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

ul div li {
    width: 33%;
    position: relative;
}
.image{
    width: 33%;
    text-align: center;
    margin: 0 auto;
}
.image img{
    text-align: center;
}</style>
